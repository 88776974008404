module.exports = [{
      plugin: require('/Users/davegavigan/dev/thestartuplab/tsl-web/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/davegavigan/dev/thestartuplab/tsl-web/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/Users/davegavigan/dev/thestartuplab/tsl-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
